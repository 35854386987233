



































































import {
  TableColumn,
  TableComponent,
} from '@dnb-open-banking/vue-table-component';
import {
  ApiGrantWithApiDto,
  ProductionGrantDto,
} from '@portals/shared/admin/ApiGrantDto';
import { format } from 'date-fns';
import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';
import Loader from '@/components/Loader.vue';
import TabsFilter from '@/components/TabsFilter.vue';
import { fetchApiGrants } from '@/service/apiGrantService';

import { FilterValue } from '../../models/FilterValues';

type App = ProductionGrantDto & {
  grants: ApiGrantWithApiDto[];
  hasPendingGrants: boolean;
  hasRejectedGrants: boolean;
  hasApprovedGrants: boolean;
  hasContactedGrants: boolean;
};

type Data = {
  apps: App[];
  statusFilter: string;
  sortKey: string;
  reverse: boolean;
  errorstat: string;
  loading: boolean;
};

export default Vue.extend({
  name: 'api-grants',

  components: {
    Loader,
    TableComponent,
    TableColumn,
    DnbIcon,
    TabsFilter,
  },
  data(): Data {
    return {
      apps: [],
      statusFilter: 'pending',
      sortKey: 'email',
      reverse: false,
      errorstat: '',
      loading: false,
    };
  },
  computed: {
    apiGrants(): any[] {
      if (this.statusFilter === 'pending') {
        return this.apps.filter((app) => app.hasPendingGrants);
      } else if (this.statusFilter === 'approved') {
        return this.apps.filter((app) => app.hasApprovedGrants);
      } else if (this.statusFilter === 'rejected') {
        return this.apps.filter((app) => app.hasRejectedGrants);
      } else if (this.statusFilter === 'contacted') {
        return this.apps.filter((app) => app.hasContactedGrants);
      } else {
        return this.apps;
      }
    },
    filterValues(): FilterValue[] {
      return [
        {
          name: 'Pending',
          value: 'pending',
        },
        {
          name: 'Approved',
          value: 'approved',
        },
        {
          name: 'Rejected',
          value: 'rejected',
        },
        {
          name: 'Contacted',
          value: 'contacted',
        },
      ];
    },
  },
  mounted() {
    this.loadApiGrants();
  },
  methods: {
    onRowClick(row: { data: App }) {
      this.$router.push(`api-grants/${row.data.id}`);
    },
    userName(appOwnerName: { [key: string]: string }) {
      if (!appOwnerName) return 'User does not exist';
      return appOwnerName;
    },
    ownerOrganization(organization: { [key: string]: string }) {
      if (!organization) return '-';
      return organization;
    },
    dateFormatter(date: string) {
      if (!date) return 'N/A';
      return format(date, 'DD.MM.YYYY HH:mm');
    },
    setStatusFilter(status: string) {
      this.statusFilter = status;
    },
    async loadApiGrants() {
      this.loading = true;
      const response = await fetchApiGrants();

      this.apps = response.map((app) => {
        const hasPendingGrants = !!app.grants.find(
          ({ status }) => status === 'pending',
        );
        const hasRejectedGrants = !!app.grants.find(
          ({ status }) => status === 'rejected',
        );
        const hasContactedGrants = !!app.grants.find(
          ({ status }) => status === 'contacted',
        );
        const hasApprovedGrants = !!app.grants.find(
          ({ status }) => status === 'approved',
        );

        return {
          ...app,
          hasPendingGrants,
          hasRejectedGrants,
          hasContactedGrants,
          hasApprovedGrants,
        };
      });

      this.loading = false;
    },
  },
});
