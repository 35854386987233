













import Vue from 'vue';

import { FilterValue } from '../models/FilterValues';
export default Vue.extend({
  name: 'tabs-filter',
  props: {
    values: {
      type: Array as () => FilterValue[],
      default: Array as () => FilterValue[],
    },
  },
  data() {
    return {
      selected: 0,
    };
  },
  methods: {
    setSelected(input: number, filterValue: string) {
      this.selected = input;
      this.$emit('change', filterValue);
    },
  },
});
