import {
  ApiGrantWithApiAndHistoryDto,
  AppWithApiGrantsAndOwnerDto,
  ProductionGrantDto,
} from '@portals/shared/admin/ApiGrantDto';

import { ApiGrantChange } from '@/models/ApiGrant';

import { request } from './request';

export async function fetchApiGrant(
  appId: string,
): Promise<AppWithApiGrantsAndOwnerDto> {
  const response = await request<AppWithApiGrantsAndOwnerDto>({
    url: `api-grants/${appId}`,
    method: 'GET',
  });
  return response.data;
}

export async function fetchApiGrants(): Promise<ProductionGrantDto[]> {
  const response = await request<ProductionGrantDto[]>({
    url: `api-grants`,
    method: 'GET',
  });
  return response.data;
}

export async function updateGrant(
  appId: string,
  grantChanges: { [grantId: string]: ApiGrantChange },
): Promise<ApiGrantWithApiAndHistoryDto[]> {
  const changes = Object.keys(grantChanges).map((key) => ({
    grantId: key,
    ...grantChanges[key],
  }));

  const response = await request<ApiGrantWithApiAndHistoryDto[]>({
    url: `api-grants/${appId}`,
    method: 'POST',
    data: changes,
  });
  return response.data;
}
